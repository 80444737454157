import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  components: {
    DdButton: function DdButton() {
      return import('~/components/DdButton.vue');
    },
    PanelButton: function PanelButton() {
      return import('~/components/panels/PanelButton.vue');
    }
  },
  computed: {
    authPanelStore: function authPanelStore() {
      return useAuthPanelStore();
    },
    cookieStore: function cookieStore() {
      return useCookieStore();
    },
    isUkrainian: function isUkrainian() {
      return this.cookieStore.isUkrainian;
    }
  },
  methods: {
    register: function register() {
      this.authPanelStore.$patch({
        direction: 'left',
        state: 'register'
      });
    },
    login: function login() {
      this.authPanelStore.$patch({
        direction: 'right',
        state: 'login'
      });
    },
    scrollTop: function scrollTop(path) {
      if (this.$route.path == path) this.$scrollTo('body');

      // home feed
      if (path.match('/my/') && this.$route.path.match('/my/feed')) {
        this.$scrollTo('body');
      }
    }
  }
};